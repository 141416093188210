@font-face{
  font-family: "DINProMediumItalic500";
  src: url("./assets/fonts/DINProMediumItalic500.woff") format("woff");
}
@font-face{
  font-family: "dinregularwebfont";
  src: url("./assets/fonts/dinregularwebfont.woff") format("woff");
}
html,body{ height:100%; display:flex; flex-direction:column; background:#f9f9f9;
  main{ display:flex; flex-direction:column; flex:1; overflow:hidden;}
}
h1,h2,h3,h4,h5,h6{ font-family:"DINProMediumItalic500", Arial;}
a{ color:#82BC00; text-decoration: none;
  &:hover{ color:#7A9C49; text-decoration: underline;}
}
.container{ max-width:1140px;}

/****** TABLE
****************************************************************************************************************/
.table-striped>tbody>tr:nth-of-type(odd)>*{ box-shadow:none; background-color:#f6f6f6;}
.table-light tr th{ color: #495057; background-color: #e9ecef; border-color: #dee2e6;}
.action-btns{ min-width: 210px;}
.background-color-1{ background-color:#5bc0de !important;}
.background-color-2{ background-color:#337ab7 !important;}
.background-color-3{ background-color:#28a745 !important;}
.background-color-4{ background-color:#d9534f !important;}
.background-color-5{ background:#E2CC00 !important;}

/****** HEADER
****************************************************************************************************************/
header{
  .navbar-brand{
    img{ max-width:120px; width:100%;}
    .after-login-logo{ max-width:120px;}
    @media screen and (max-width:992px){
      flex:1;
    }
  }
  .navbar-toggler{ padding:4px 8px; box-shadow:none !important;}
  .first-nav{
    li:nth-child(2){
      .nav-link{ background-color:#0080BB; border-radius:100px; color:#FFF; padding:8px 15px;}
    }
  }
  .nav-link{ color:#FFF; font-family:"DINProMediumItalic500", Arial; font-size:1rem;
    &:hover{ color:rgba(255,255,255,0.75);}
    &:focus,&.show{ color:#FFF !important;}
  }
  .colors-dropdown{ min-width:700px;}
  .resources-dropdown{ min-width:500px;}
  //.dropdown-item{ text-wrap:balance;}
  @media screen and (max-width:992px){
    .navbar-collapse{ max-height:450px; overflow:auto;}
    .colors-dropdown{ min-width:auto;}
    .resources-dropdown{ min-width:auto;}
  }
  .submenu{
    .dropdown-menu{
      animation-duration: .25s; animation-fill-mode: forwards; animation-iteration-count: 1;
      animation-name: dropdownAnimation; animation-timing-function: ease; display: none;
      @keyframes dropdownAnimation {
        0% { opacity: 0; transform: translateY(-8px);}
        100% { opacity: 1; transform: translate(0);}
      }
    }
    &:hover{
      .dropdown-menu{ display:block; left:100%; top:9px;}
    }
  }
}

/****** BUTTONS
****************************************************************************************************************/
.btn{ border-radius:2px; font-size:14px;}
.btn-primary{ border-color:#2e6da4; background:$brand-primary;
  &:hover{ background-color:#286090; border-color:#204d74;}
  &:active{ background-color:#0062cc; border-color:#005cbf;}
  &:focus{ background-color:#286090; border-color:#122b40; box-shadow: 0 0 0 0.2rem rgba(38,143,255,0.5);}
}
.btn-success{ border-color:#4cae4c; background:#82BC00;
  &:hover{ background-color:#449d44; border-color:#398439;}
  &:focus{ box-shadow:0 0 0 0.2rem rgba(72,180,97,0.5);}
}
.btn-outline-secondary{ color:#333; background-color:#fff; border-color:#ccc;
  &:hover{ color:#333; background-color: #e6e6e6; border-color: #adadad;}
}
.btn-danger{ color: #fff; background-color: #d9534f; border-color: #d43f3a;
  &:hover{ background-color: #c9302c; border-color: #ac2925;}
}
.btn-warning{ color: #fff; background-color:#E2CC00; border-color:#E2CC00;
  &:hover{ background-color:#E2CC00; border-color:#E2CC00; color: #fff;}
}

/****** FORM CONTROLS
****************************************************************************************************************/
.form-control{ border-radius: 0.25rem; border: 1px solid #ced4da;}
.form-label{ display:flex; align-items:center; justify-content:end; max-height:37px; margin:0; text-align:right;
  @media screen and (max-width:992px){
    justify-content:start; text-align:left; max-height:none;
  }
}

/****** BRANDING COLORS
****************************************************************************************************************/
.text-primary{ color:$brand-primary !important;}
.text-primary-dark{ color:$brand-primary-dark !important;}
.text-secondary{ color:$brand-secondary !important;}
.text-secondary-green{ color:$brand-secondary-green;}
.text-secondary-orange{ color:$brand-secondary-orange;}
.text-primary-grey{color:$brand-primary-gray}
.text-link{ color:#82BC00; cursor: pointer;
  &:hover{ text-decoration-color:#82BC00 !important;}
}
// .bg-warning { background-color: #fff3cd !important; .toast-body, .text-14 {color: #664d03 !important;} }
.bg-primary{ background-color:$brand-primary !important;}
.bg-primary-sky{ background-color:$brand-primary-sky !important;}
.bg-primary-gray{ background-color:$brand-primary-gray !important;}

/****** CARDS
****************************************************************************************************************/
.card{ margin-bottom: 20px;
  .card-header{ padding:0.75rem 1.25rem; background-color:$brand-secondary-green;
    border-bottom:1px solid rgba(0,0,0,0.125);
    h4{ color:#FFF; font-size:1.875rem; margin:0;}
  }
}


/****** PAGINATION
****************************************************************************************************************/
.pagination{
  .page-item.active{
    .page-link{ background-color:$brand-primary;}
  }
}

.dropdown-item.active, .dropdown-item:active{ color:#fff; background-color:#007bff;}


.alert-warning{ color:#856404;}

.case-progress-bar{ overflow:hidden; border-radius:0; display:flex; background-color:$brand-primary-gray;
  .case-creation-progress-step{ background:transparent; position:relative; color:#FFF;
    flex:1; border-radius:0; border:0; padding:6px 4%;
    //&:hover{ background-color:#FFF;}
    .progression-arrow-head{ color:transparent; font-size:70px; z-index:1;
      position:absolute; left:100%; top:0; bottom:0; display:flex; align-items:center;
      &:before{ text-indent:-22px;}
      @media screen and (max-width:768px){
         font-size:50px;
      }
    }
    &.active-progress-step{ background-color:#80BC00; color:#FFF;
      .progression-arrow-head{ color:#80BC00;}
    }
    &.invalid-progress-step{ background-color:#E2CC00; color:#FFF;
      .progression-arrow-head{ color:#E2CC00;}
    }
  }
}

.after-login-trademark{
  .copyright-text{ text-align:right !important;}
}

.footer-policy{
  .links{ display:flex; justify-content:center; flex-wrap:wrap;
    a{ color:#FFF; text-decoration:none; text-transform:uppercase; padding:4px 10px; font-size:19px;
      font-family:"titillium-regular", Arial;
    }
  }
  .accordion-button{ color:$brand-secondary-green; font-size:18px !important; justify-content:center; padding:8px;
    background:$brand-secondary-green; box-shadow:none;
    &:after{ display:none;}
  }
  .accordion{ background-color:#fff;
    .accordion-collapse{ max-width:75rem; max-height:40vh; margin:0 auto; overflow:auto; padding:0 8px;
      .text-primary{ color:#82BC00 !important;}
    }
  }
}

.statistics-filter{ display:inline-flex;
  .col{
    &.min-col{ min-width:250px;}
    &:last-child{ flex:0; min-width:125px;}
  }
  .row{ margin-bottom:0 !important; flex-wrap:nowrap;
    .form-label{ justify-content: start; width:auto; flex:0;}
    .col-lg-8{ flex:1;}
  }
  @media screen and (max-width:1000px){
    .col{ flex:0 0 50%;}
  }
  @media screen and (max-width:700px){
    .col{ flex:0 0 100%;}
  }
}


.link-modal:hover {
  cursor: pointer;
  text-decoration: underline;
}

.show-modal {
  display: block !important;
  backdrop-filter: brightness(0.3);
}

/****** MEDIA QUERY
****************************************************************************************************************/
@media (max-width: 600px){
  .footer-detail{ font-size: 14px;}
  .footer-policy{
    .accordion{ font-size: 14px;
      .accordion-button{ font-size: 14px !important;}
    }
  }
  .statistics-filter{
    .row{
      .form-label{ width: 0; min-width: 50px;}
    }
  }
  .login-sec{
    .login-hero-img{ max-width: 250px !important;}
  }
  .local-coverage-card{
    h4{ font-size: 28px !important;}
  }
  .navbar-brand{
    img{ max-width: 150px !important;}
  }
  .action-btns{ min-width: auto;}
}