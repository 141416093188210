$brand-primary:#337ab7;
$brand-primary-dark:#00365b;
$brand-primary-sky:#ccedf9;

$brand-secondary:#DF5A27;
$brand-tertiary:#FB637E;

$brand-primary-gray:#808285;
$brand-secondary-orange:#F1B434;
$brand-secondary-green:#7A9C49;


