@import "variables";
@import "generic";


/****** IMAGES
****************************************************************************************************************/
.body-bg{ background-size:cover; background-repeat:no-repeat; background-position:center;
  //background-image:url('./assets/images/login-bg.jpg');
}
.pacira-logo{ max-width:140px; width:100%;}

/****** LOGIN SCREEN
****************************************************************************************************************/
.login-sec{
  .login-hero-img{ width:100%; max-width:350px;}
  .card-header{ background-color:$brand-primary-gray;}
}

.page-link{ color:#000;}

.prescribing-accordion{
  .accordion-item{ border:0;}
  .accordion-header{
    .accordion-button{ color:$brand-secondary-orange;  background: #cdedfa; border-radius:0 !important; font-size:14px;
      justify-content:center;
      &:focus{ box-shadow:none;}
      &:after{ display:none;}
    }
  }
  @media screen and (max-width:1000px){
    .accordion-body{ max-height:450px; overflow:auto;}
  }
}

.cases-page{
  .table{
    thead{ position:sticky; top:0;}
  }
}

.btn-localCoverage{ background-color:#0cb5b3; color:#FFF; padding: 8px 15px;
  &:hover{ background-color:#0cb5b3; color:#FFF;}
}

.shepherd-theme-custom {
  border: 1px solid gainsboro !important;
  box-shadow: none;
  padding: 0 !important;
  width: 19rem !important;
  margin: 5px !important;
  .shepherd-content {
    .shepherd-header {
      padding-inline: 5px !important;
      padding: 8px 14px !important;
      border-bottom: 1px solid gainsboro;
      background-color: #f7f7f7 !important;
      .shepherd-title{
        font-family: Arial !important;
        font-size: 14px;
        color: #212529;
      }
    }
    .shepherd-text{
      font-size: 14px;
      color: #212529;
    }
  }
  .shepherd-footer {
    button.shepherd-button  { padding: 5px 10px; font-size: 12px; margin: 0; color: #333; background-color: #fff;
      border: 1px solid #ccc;
      &:hover{ color: #333; background-color: #e6e6e6; border-color: #adadad; }
      i { font-size: 10px !important; }
    }
    button:disabled { cursor: not-allowed; opacity: 0.5 !important;
      &:hover{ background-color: #fff; border-color: #ccc; }
    }
    button:first-of-type {
      //margin-right: auto !important;
      //text-align: start;
    }
    button:nth-of-type(2) {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
    button:nth-of-type(3) {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
      margin-left: auto;
    }
  }
}

li.submenu ul.dropdown-menu[data-bs-popper] {
  border-width: 0;
  padding-top: 0;
  padding-bottom: 1em;
}

li.submenu ul.dropdown-menu[data-bs-popper] li {
  margin-left: 1.5em;
}

li.submenu>a.dropdown-toggle.show::after {
  transform: rotate(90deg);
}

li.submenu.dropinline>a.dropdown-toggle.show::after {
  transform: rotate(0deg);
}

li.submenu.dropinline>a.dropdown-toggle::after {
  transform: rotate(-90deg);
}

li.submenu.dropstart>a.dropdown-toggle::before {
  display: none;
}

li.submenu.dropstart>a.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

@media (min-width: 768px) {

  /* common styles */
  li.submenu-md ul.dropdown-menu[data-bs-popper] {
      border-width: var(--bs-dropdown-border-width);
      padding-top: var(--bs-dropdown-padding-y);
      padding-bottom: var(--bs-dropdown-padding-y);
      margin-top: -0.5em;
  }

  li.submenu-md ul.dropdown-menu[data-bs-popper] li {
      margin-left: 0;
  }

  li.submenu-md a[role="button"] {
      display: inline-flex;
      align-items: center;
  }

  /* dropend styles */
  li.submenu-md.dropend ul.dropdown-menu[data-bs-popper] {
      margin-left: -0.3em;
  }

  li.submenu-md.dropend a[role="button"].show::after {
      transform: unset;
  }

  li.submenu-md.dropend a[role="button"]::after {
      position: absolute;
      right: 0.4em;
  }

  /* dropstart styles */
  li.submenu-md.dropstart ul.dropdown-menu[data-bs-popper] {
      margin-right: -0.3em;
  }

  li.submenu-md.dropstart a[role="button"]::before {
      display: block;
      position: absolute;
      left: 0.4em;
  }

  li.submenu-md.dropstart a[role="button"]::after {
      display: none;
  }

  li.submenu-md.dropinline ul.dropdown-menu[data-bs-popper] {
      position: relative;
      width: 100% !important;
      border: 0;
      box-shadow: none;
      background-color: transparent;
  }

  li.submenu-md.dropinline ul.dropdown-menu[data-bs-popper] li {
      padding-left: 1em;
  }
}
